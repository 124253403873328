
//FONTS
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


// VARIABLES
$color_validation_error: #FF9B9B;
$color_prime: #FFA500;
$bg_section_1: '../img/banner1.svg';
$bg_lng_en: '../img/en.png';
$bg_lng_lt: '../img/lt.png';


.slick-prev:before,
.slick-next:before {
  color: lightgrey!important;
}

//MIXINS
@mixin font_family_Inter($weight) {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: $weight;
    font-style: normal;
}

@mixin font_family_NunitoSans($weight) {
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: $weight;
    font-style: normal;
}

@mixin font_family_Montserrat($weight) {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: $weight;
    font-style: normal;
}

@mixin position($position, $top, $right, $bottom, $left, $z_index) {
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
    z-index: $z_index;
    position: $position;
}

@mixin position_absolute($top, $right, $bottom, $left, $z_index: 1) {
    @include position(absolute, $top, $right, $bottom, $left, $z_index)
}

//COMMON
body,
html {
    outline: none;
    margin: 0;
    padding: 0;
}

body {
    position: relative;
    padding: 0;
    margin: 0;
    @include font_family_Inter(500);
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}

.hide {
    display: none !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    text-decoration: none !important;

    &:focus {
        outline: none;
    }
}

button:focus {
    outline: none;
}

ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

.masterPage_content {
    overflow: hidden;
    width: 100%;
}

//dev UI
div {
    // box-shadow: 0 0 1px red;
}


.container {
    margin: 0 auto;


    @media (min-width:230px) {
        width: calc(100% - 20px);
    }

    @media (min-width: 1200px) {
        width: calc(100% - 200px);
    }

    @media (min-width: 1400px) {
        width: 1200px;
    }

    @media (min-width: 1800px) {
        width: 1600px;
    }
}

//HEADER
header {
    height: 80px;
    width: 100%;
    box-shadow: 0 0 10px -8px #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #002f5e;
    z-index: 2;

    > .container {
        display: flex;
        justify-content: space-between;
    }

    .logo {
        display: flex;

        img {
            width: 48px;
        }

        .name_group {
            padding: 12px 0 0 10px;
        }

        .name {
            text-transform: uppercase;
            font-size: 24px;
            line-height: 14px;
            color: white;
            @include font_family_Montserrat(700);

            @media (max-width:960px) {
                font-size: 20px;
            }
        }

        .slogan {
            color: #B0B0B0;
            display: block;
            font-size: 11px;
            line-height: 14px;
            text-transform: uppercase;
            white-space: nowrap;

            @media (max-width:960px) {
                font-size: 9px;
            }
        }

	.name:hover, .slogan:hover {
    	    color: lightgrey;
    	    transition: color 0.3s ease;
	}
    }

    .menu {
        display: flex;
        align-items: center;
        margin-right: -6px;

        ul {                                                            	
            li {
                display: inline-flex;
                list-style-type: none;
                margin: 0 10px;

                a, div {
                    color: #FFFFFF;
                    position: relative;
                    font-size: 24px;
                    line-height: 30px;
                    @include font_family_NunitoSans(600);

                    &:hover {
                        cursor: pointer;

                        &:before {
                            content: '';
                            @include position_absolute(auto,6px,0,6px,1);
                            height: 2px;
                            background-color: $color_prime;
                        }
                    }
                }
            }
        }
    }

    .language {
        max-width: 100px;

        .ant-select-selector {
            background-color: transparent !important;
            border: none !important;

            .ant-select-selection-item {
                @include font_family_NunitoSans(600);
                font-size: 16px;
            }
        }
    }

    @media (max-width:1199px) {
        .menu,
        .language {
            display: none;
        }
    }
}

.mobile_menu_btn {
    display: none;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    float: right;
    /* @include position_absolute(50%,0,auto,auto,10);
    transform:translateY(-50%);*/
    svg {
        font-size: 30px;
    }

    @media (max-width:1199px) {
        display: flex;
    }
}

.mobile_menu_modal {

    .ant-modal-content {
        padding: 80px 40px;
        text-align: center;
    }

    ul {
        margin-bottom: 20px;

        li {
            a, div {
                @include font_family_NunitoSans(600);
                font-size: 34px;
                padding: 4px 0;
                color: #333333;
                user-select: none;

                &:hover {
                    cursor: pointer;
                    background-color: rgba(#1282c4,0.05);
                }
            }
        }
    }

    .language_list {
        width: 100%;
        min-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 20px;

        &:before {
            content: '';
            width: 60px;
            border-top: 4px solid #1282c4;
            @include position_absolute(0,auto,auto,50%);
            transform: translateX(-50%);
        }

        .language_list_wrapper {
            height: 60px;
            display: flex;

            .language_option {
                background: none;
                padding-left: 0;
                @include font_family_NunitoSans(800);
                font-size: 18px;
                line-height: 60px;
                width: 60px;
                height: 60px;
                user-select: none;

                &.isActive {
                    color: #1282c4;
                }

                &:hover {
                    cursor: pointer;
                    background-color: rgba(#1282c4,0.05);
                }
            }
        }
    }
}

.language_group {
    position: relative;
    padding-bottom: 2px;
    margin-left: 20px;
    color: white;
}

.language_option {
    padding-left: 40px;
    text-transform: uppercase;
    cursor: pointer;
    @include font_family_NunitoSans(600);
    font-size: 16px;

    background: {
        position: center left;
        repeat: no-repeat;
        size: 30px;
    }

    &.en {
        background-image: url($bg_lng_en);
    }

    &.lt {
        background-image: url($bg_lng_lt);
    }
}

.ant-dropdown-menu {
    .language_option {
        height: 26px;
        line-height: 27px;
    }
}

//SLIDER
.carousel_top {
    .slick-list {
        background: {
            image: url($bg_section_1);
            position: center;
            repeat: no-repeat;
            size: contain;
        }

        @media (max-width:1200px) {
            background-size: cover;
        }
    }

    .slick-dots li {
        list-style-type: none;
    }

    .slick-dots.slick-dots-bottom {
        bottom: 20px;

        li {
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                height: 10px;
                width: 10px;
                border-radius: 50%;
            }
        }
    }
}

.slick-dots.slick-dots-bottom {
    li {
        button {
            background-color: #333333;
            opacity: 0.6;

            &:before {
                display: none;
            }
        }

        &.slick-active button {
            background-color: #000;
            opacity: 1;
        }
    }
}

.slick-slide img {
    margin: 0 auto;
}
//FOOTER
footer {
    min-height: 340px;
    width: 100%;
    background-color: #00264D;
    display: flex;
    padding-top: 60px;
    justify-content: center;
    background: linear-gradient(to top, #002f5e, #00264D);

    .container {
        > div {
            @media (min-width:1100px) {
                display: flex;
            }
        }

        .footer_col {
            @media (min-width:760px) {
                padding-right: 20px;
                margin-right: 100px;
            }

            &:last-child {
                margin-right: 0;
            }

            p {
                color: white;
                @include font_family_NunitoSans(400);
                padding-bottom: 40px;

                @media (min-width: 1200px) {
                    width: auto;
                }

                @media (min-width: 1400px) {
                    width: 560px;
                }

                @media (min-width: 1800px) {
                    width: 560px;
                }
            }

            .col_title {
                font-size: 16px;
                color: white;
                text-transform: uppercase;
                position: relative;
                padding-bottom: 20px;
                margin-bottom: 20px;
                white-space: nowrap;

                &:before {
                    content: '';
                    @include position_absolute(auto,auto,0,0);
                    width: 60px;
                    height: 2px;
                    background-color: $color_prime;
                }
            }

            ul {
                li {
                    padding: 4px 0;

                    svg {
                        margin-right: 6px;
                        width: 24px;
                        height: 24px;
                    }

                    a, div {
                        font-size: 16px;
                        color: white;

                        @media (min-width:760px) {
                            white-space: nowrap;
                        }
                    }
                }
            }

            &.footer_contact {
                li {

                    &.li_address {
                        span {
                            width: 100%;
                            display: block;
                        }
                    }

                    div {
                        @media (min-width:760px) {
                            display: flex;
                        }
                    }
                }
            }

            a.link {
                text-decoration: underline !important;
                padding-left: 10px;
            }
        }
    }

    .logo {
        display: flex;

        img {
            width: 40px;
        }

        .name_group {
            padding: 6px 0 0 10px;
        }

        .name {
            text-transform: uppercase;
            font-size: 24px;
            line-height: 14px;
    	    color: #F4F4F4; /* Light Gray */
	    font-weight: 600; /* Semi-bold for visibility */


            @include font_family_Montserrat(700);
        }

        .slogan {
            display: block;
            font-size: 11px;
            line-height: 14px;
            text-transform: uppercase;
            color: #B0B0B0;
        }
    }

    .footer_menu ul li {
        a, div {

            &:hover {
                cursor: pointer;
                text-decoration: underline !important;
            }
        }
    }
}

@media (max-width:759px) {
    footer {
        padding: 40px 10px 0;

        .logo_col {
            .logo {
                display: flex;
                justify-content: center;
            }
        }

        .logo {
            margin: 0 auto;

            .name_group {
                .name {
                    font-size: 18px;
                }

                .slogan {
                    font-size: 10px;
                }
            }
        }

        .container {
            .footer_col {
                margin-bottom: 40px;

                .col_title {
                    padding-bottom: 10px;
                    margin-bottom: 10px;

                    &:before {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                &.footer_menu,
                &.footer_contact,
                p {
                    text-align: center;
                }
            }
        }
    }
}

@media (min-width:760px) and (max-width:1099px) {
    footer {
        .footer_col {
            float: left;
            margin-right: 0 !important;
        }

        .logo_col {
            padding-left: 20px;
            width: 50%;
            min-height: 100%;
        }

        .footer_menu {
            width: 50%;
        }

        .footer_contact {
            padding-bottom: 60px;
        }
    }
}

//COPYRIGHT
.copyright {
    height: 48px;
    color: #333333;
    text-align: left;
    font-size: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include font_family_Inter(500);

    .container {
        position: relative;
    }

    .privacy_polici_btn {
        @include font_family_Inter(500);
        color: #333333;
        margin-left: 20px;
        padding: 20px;
        position: relative;
        text-decoration: underline !important;

        &:before {
            content: '';
            @include position_absolute(50%,auto,auto,0);
            transform: translate(0,-50%);
            height: 15px;
            border-left: 1px solid #333333;
        }
    }

    .social {
        @media (min-width:760px) {
            @include position_absolute(50%,0,auto,auto);
            transform: translateY(-50%);
        }

        ul {
            display: flex;

            li {
                margin-left: 10px;

                a {
                    width: 24px;
                    height: 24px;
                    display: block;

                    img {
                        width: 100%
                    }
                }
            }
        }
    }

    @media (max-width:759px) {
        .privacy_polici_btn {
            margin: 0 auto;
            display: block;

            &:before {
                display: none;
            }
        }

        .social ul {
            margin: 10px auto 20px;
            display: inline-flex;

            li {
                margin: 0 5px;
            }
        }
    }
}

@media (max-width:759px) {
    .copyright {
        height: auto;
        min-height: 48px;
        padding: 30px 10px 10px;
        text-align: center;
    }
}

//SECTION STYLE
.section_container {
    &.section_middle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.section_1 {
        .container {
            > div {
                @media (min-width: 1200px) {
                    padding: 80px 20px 100px;
                }

                @media (min-width: 1400px) {
                    padding: 80px 100px 100px;
                }

                @media (min-width: 1800px) {
                    padding: 80px 200px 100px;
                }
            }

            @media (max-width: 1200px) {
                width: calc(100% - 60px);
            }
        }

        h1 {
            color: #0057B7;
            font-size: 60px;
	    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            line-height: 1;
            @include font_family_Montserrat(600);
            margin-bottom: 10px;
            text-align: left;

            @media (max-width: 1200px) {
                font-size: 40px;
            }
        }

        h2 {
            color: #003366;
            font-size: 30px;
            line-height: 1;
	    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            @include font_family_Montserrat(500);
            margin: 0px 0px 40px;
            text-align: left;

            @media (max-width: 1200px) {
                font-size: 24px;
            }
        }

        p {
            color: #333333;
            font-size: 24px;
            @include font_family_Inter(400);
            line-height: 36px;

            @media (max-width: 1200px) {
                font-size: 20px;
                line-height: 28px;
            }

            &:nth-of-type(2) {
                margin-bottom: 40px;
            }
        }

        .btn_wrapper {
            display: flex;
            margin: 0 0 80px;

            .btn {
                height: 64px;
                padding: 20px 40px;
                text-transform: uppercase;
                font-size: 16px;
                color: white;
                border: 1px solid #ebebeb;
                @include font_family_Inter(600);
                background-color: #FFA500;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &.section_technologies {
        padding: 40px 0;
        background-color: #e6f0f6;

        img {
            pointer-events: none;
        }
    }

    &.section_services {
        padding: 60px 0;

        .section_box_wrapper {
            display: grid;
            grid-gap: 10px;
            padding: 20px;

            @media (min-width:1200px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width:760px) and (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:759px) {
                grid-template-columns: repeat(1, 1fr);
            }


            .box_item {
                padding: 40px 40px 60px;
                text-align: center;
                box-shadow: 0 0 10px -8px #333333;
                border-radius: 8px;
                text-align: center;
                background-color: #f7fcff;
                position: relative;
                transition: all 254ms linear;

                &:before {
                    content: '';
                    @include position_absolute(0,0,0,0,1);
                    border: 1px solid #fbfbfb;
                    border-radius: 8px;
                    pointer-events: none;
                }

                .item_icon {
                    margin-bottom: 10px;
                }

                .item_title {
                    font-size: 24px;
                    margin: 20px 0;
                    color: #2A4365;
                    @include font_family_Inter(700);
                }

                .item_value {
                    color: #585858;
                    font-size: 20px;
                    line-height: 30px;
                    @include font_family_NunitoSans(400);
                }

                &:hover {
                    box-shadow: 0 0 10px -4px #333333;
                }
            }
        }
    }

    &.section_expertise {
        padding: 40px 0 40px;
        background-color: #EDF2F7;

        .text_group {
            margin: 5px auto;
            display: grid;
            grid-gap: 10px;
            padding: 20px;
            grid-template-columns: repeat(5, 1fr);

            @media (min-width:760px) and (max-width:1200px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width:480px) and (max-width:759px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:479px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .group_item {
                text-align: center;
                padding: 0 30px;
                margin: 10px 0 20px;

                &:nth-of-type(6) {
                    margin-left: 1fr;

                    @media (max-width:1200px) {
                        display: none;
                    }
                }

                .item_icon {
                    height: 72px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    &.section_values {
        padding: 80px 0 20px;

        p {
            color: #333333;
            font-size: 24px;
            @include font_family_Inter(400);
            line-height: 36px;
            margin-bottom: 40px;
            padding: 0 100px;
	    box-shadow: 0 0 10px -8px #333333;
            background-color: #f7fcff;
            text-align: center;

            @media (max-width:759px) {
                padding: 0 10px;
            }
        }

        .text_group {
            margin: 20px auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            padding: 20px;

            .group_item {
                padding: 20px 30px 30px;
                border: 1px solid #f5f5f5;
                border-radius: 8px;
                transition: all 254ms linear;
                background-color: #f7fcff;
                display: flex;
                align-items: center;

                .item_icon {
                    height: 72px;
                    width: 100px;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    padding-left: 10px;
                }

                .item_title {
                    font-size: 18px;
                    margin: 20px 0;
                    @include font_family_Inter(700);
                    color: #2a4365;
                }

                .item_text {
                    color: #333333;
                    font-size: 20px;
                    line-height: 26px;
                    @include font_family_NunitoSans(400);
                }

                .text_col {
                    width: calc(100% - 100px);
                }

                &:hover {
                    box-shadow: 0 0 10px -6px #333333;
                }
            }
        }

        @media (max-width:759px) {
            .text_group {
                grid-template-columns: repeat(1, 1fr);
                text-align: center;

                .group_item {
                    display: block;

                    .item_icon {
                        height: 72px;
                        width: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-left: 0;
                        margin: 0 auto;
                    }

                    .text_col {
                        width: 100%;
                    }
                }
            }
        }
    }

    .section_title {
        font-size: 30px;
        text-align: center;
	color: #003366;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        text-transform: capitalize;
        margin: auto auto 60px;
        @include font_family_Inter(400);

        span {
            position: relative;
	    color: #0057B7;
            font-size: 40px;
            @include font_family_Inter(600);
        }
    }
}

.aboutUs_page {
    position: relative;
    background: #EDF2F7;
    padding: 60px 0;

    .contact_text,
    .contact_form {
        @media (min-width:760px) {
            width: calc(100%/2);
        }

        @media (max-width:759px) {
            width: calc(100%);
        }
    }

    .contact_text {
        display: flex;
        justify-content: center;
        align-items: start;
        padding: 50px 100px;

        > div {
            width: 400px;
        }
    }

    h1 {
        font-size: 38px;
	color: #0057B7;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        @include font_family_Inter(700);
    }

    h2 {
        @include font_family_NunitoSans(400);
        color: #003366;
        font-size: 18px;
        margin-bottom: 30px;
    }


    p {
        @include font_family_NunitoSans(400);
        color: #8d8d8d;
        font-size: 18px;
        margin-bottom: 30px;

        a {
            @include font_family_NunitoSans(400);
            color: #50585e;
            font-size: 18px;
            text-decoration: underline !important;
        }
    }

    .contact_form {
        position: relative;

        form {
            padding: 20px 50px 30px;


            @media (min-width: 1200px) {
                width: auto;
            }

            @media (min-width: 1400px) {
                width: 680px;
            }

            @media (min-width: 1800px) {
                width: 680px;
            }

            .input_group {
                display: flex;

                .input_control {
                    width: calc((100% - 20px)/2);

                    &:last-child {
                        margin-left: 20px;
                    }
                }
            }

            .input_control {
                position: relative;
                margin-bottom: 30px;

                &:nth-of-type(4) {
                    margin-bottom: 10px;
                }

                label {
                    @include font_family_NunitoSans(600);
                    color: #333333;
                    font-size: 18px;
                    margin-bottom: 8px;
                    display: flex;
                    position: relative;
                }

                input,
                textarea {
                    background: #fff;
                    border: 1px solid #ebebeb;
                    width: 100%;
                    height: 50px;
                    @include font_family_NunitoSans(400);
                    font-size: 16px;
                    line-height: 20px;
                    color: #444;
                    padding-left: 16px;
                    border-radius: 8px;
                }

                > div {
                    @include position_absolute(100%,0,auto,0,1);
                    //background-color: #fce3b6;
                    font-size: 11px;
                    text-transform: uppercase;
                    color: #fe4242;
                    @include font_family_NunitoSans(800);
                    padding: 2px 10px;
                }

                textarea {
                    min-height: 100px;
                    max-height: 120px;
                    margin-bottom: 10px;
                    padding-top: 10px;
                }
            }

            .submit_btn_row {
                height: 40px;
                width: 100%;

                .submit_btn {
                    float: right;
                    background-color: #FFA500;
                    color: #fff;
                    padding: 0 40px;
                    @include font_family_NunitoSans(400);
                    font-size: 16px;
                    line-height: 1;
                    height: 40px;

                    border: {
                        radius: 0;
                        width: 0;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media (min-width:760px) {
        .container {
            display: flex;
        }
    }

    @media (max-width:759px) {
        padding: 60px 10px;

        .contact_text {
            padding: 0 10px 20px;

            > div {
                width: 100%;
            }
        }

        .contact_form {
            position: relative;

            form {
                padding: 20px 0 30px;

                .input_group {
                    display: block;

                    .input_control {
                        width: calc(100%);

                        &:last-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.privacyPolicy_page {
    .container {
        /*margin: auto;
        width: 1224px;*/
        max-width: 1224px;
    }

    .bg-padding {
        padding: 30px 0 128px;

        @media (max-width:1200px) {
            padding: 30px 20px 128px;
        }
    }

    h3 {
        margin-top: 60px;
        color: #040b26;
        font-size: 50px;
        font-weight: 500;
        line-height: 48px;
    }

    small {
        font-size: 14px;
        line-height: 21px;
    }

    h2 {
        margin-bottom: 24px;
        font-size: 36px;
        font-weight: 400;
        line-height: 54px;
    }

    p {
        color: #040b26;
        font-size: 16px;
        line-height: 26.6px;
    }

    p,
    ul {
        margin-bottom: 32px;
        list-style-type: disc;

        li {
            margin-left: 16px;
        }
    }

    h1, h2 {
        color: #040b26;
    }

    h4 {
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 24px;
    }

    h4, h5 {
        color: #040b26;
        font-weight: 500;
    }

    p.sm-m,
    ul.sm-m {
        margin-bottom: 16px;
    }

    p.sm-m1,
    ul.sm-m1 {
        margin-bottom: 8px;
        margin-top: 8px;
    }

    p, small {
        font-weight: 400;
    }

    p.sm-m,
    ul.sm-m {
        margin-bottom: 16px;
    }

    .gray-href {
        color: #a3a4a5;
        text-decoration: none;
        transition: .5s;
    }
}
